var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pb-2"
  }, [_c('SearchETicketPage', {
    attrs: {
      "source": "1G",
      "header": _vm.$t('reservation.viewEticket1G')
    }
  }), _c('SearchShortenBooking'), _c('SendOutsideEmail')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }